import React from 'react';
import { CustomSelect } from './Select';

const optionsAll = [
  { value: 'Balansrapport - fastighetsutvecklare', label: 'Balansrapport - fastighetsutvecklare' },
  { value: 'Balansrapport - projektbolag', label: 'Balansrapport - projektbolag' },
  { value: 'Balansrapport - bostadsrättsförening', label: 'Balansrapport - bostadsrättsförening' },
  { value: 'Balansrapport - moderbolag', label: 'Balansrapport - moderbolag' },
  { value: 'Boverket garanti', label: 'Boverket garanti' },
  { value: 'Bygglov', label: 'Bygglov' },
  { value: 'Ekonomisk plan', label: 'Ekonomisk plan' },
  { value: 'Fastighetskalkyl', label: 'Fastighetskalkyl' },
  { value: 'Fastighetsutdrag', label: 'Fastighetsutdrag' },
  { value: 'Finansieringskalkyl', label: 'Finansieringskalkyl' },
  { value: 'Förhandsavtal', label: 'Förhandsavtal' },
  { value: 'Förteckning fastigheter/tillgångar i koncernen', label: 'Förteckning fastigheter/tillgångar i koncernen' },
  { value: 'Hyreslista/rentroll', label: 'Hyreslista/rentroll' },
  { value: 'Info kring hyreskö', label: 'Info kring hyreskö' },
  { value: 'Investeringsstöd', label: 'Investeringsstöd' },
  { value: 'Kassaflödeskalkyl', label: 'Kassaflödeskalkyl' },
  { value: 'Kassaflödeskalkyl färdigställd fastighet', label: 'Kassaflödeskalkyl färdigställd fastighet' },
  { value: 'Köpeavtal förvärv', label: 'Köpeavtal förvärv' },
  { value: 'Köpeavtal på fastigheterna i projektet', label: 'Köpeavtal på fastigheterna i projektet' },
  { value: 'Lyftplan/tidsplan', label: 'Lyftplan/tidsplan' },
  { value: 'Offert föreningslån', label: 'Offert föreningslån' },
  { value: 'Offert slutfinansiering färdigställd fastighet', label: 'Offert slutfinansiering färdigställd fastighet' },
  { value: 'Oreviderad balansrapport', label: 'Oreviderad balansrapport' },
  { value: 'Oreviderad resultatrapport', label: 'Oreviderad resultatrapport' },
  { value: 'Projektkalkyl', label: 'Projektkalkyl' },
  { value: 'Projektpresentation', label: 'Projektpresentation' },
  { value: 'Resultatrapport', label: 'Resultatrapport' },
  { value: 'Signerat entreprenadavtal', label: 'Signerat entreprenadavtal' },
  { value: 'Slutbesked', label: 'Slutbesked' },
  { value: 'Startbesked', label: 'Startbesked' },
  { value: 'Underlag nuvarande finansiering', label: 'Underlag nuvarande finansiering' },
  { value: 'Underlag som styrker återbetalning av brygga', label: 'Underlag som styrker återbetalning av brygga' },
  { value: 'Uppdaterat koncernschema upp till fysiska personer', label: 'Uppdaterat koncernschema upp till fysiska personer' },
  { value: 'Upplåtelseavtal', label: 'Upplåtelseavtal' },
  { value: 'Värdering fastighet', label: 'Värdering fastighet' },
  { value: 'Värdering som färdigställd hyresfastighet', label: 'Värdering som färdigställd hyresfastighet' },
  { value: 'Årsredovisning', label: 'Årsredovisning' }
];

const optionsHyresratter = [
  {value: "Aktieöverlåtelseavtal fastighetsförvärvet", label: "Aktieöverlåtelseavtal fastighetsförvärvet"},
  {value: "Beskrivning hyregästtillträde", label: "Beskrivning hyregästtillträde"},
  {value: "Boverket garanti", label: "Boverket garanti"},
  {value: "Bygglov", label: "Bygglov"},
  {value: "CV på utvecklaren", label: "CV på utvecklaren"},
  {value: "Entreprenadsavtal", label: "Entreprenadsavtal"},
  {value: "Ev avtal för försäljning vid färdigställd fastighet", label: "Ev avtal för försäljning vid färdigställd fastighet"},
  {value: "Ev underleverantörsavtal", label: "Ev underleverantörsavtal"},
  {value: "Ev värdering av marken", label: "Ev värdering av marken"},
  {value: "Finansieringskalkyl", label: "Finansieringskalkyl"},
  {value: "Förhandsvärdering med investeringsstöd", label: "Förhandsvärdering med investeringsstöd"},
  {value: "Förhandsvärdering utan investeringsstöd", label: "Förhandsvärdering utan investeringsstöd"},
  {value: "Försäkringar för projektet (utvecklaren och byggaren)", label: "Försäkringar för projektet (utvecklaren och byggaren)"},
  {value: "Info kring hyreskö", label: "Info kring hyreskö"},
  {value: "Investeringsstöd", label: "Investeringsstöd"},
  {value: "Kassaflödeskalkyl färdigställd fastighet", label: "Kassaflödeskalkyl färdigställd fastighet"},
  {value: "Köpeavtal på fastigheterna i projektet", label: "Köpeavtal på fastigheterna i projektet"},
  {value: "Lyftplan/tidsplan", label: "Lyftplan/tidsplan"},
  {value: "Lägenhetslista", label: "Lägenhetslista"},
  {value: "Offert slutfinansiering", label: "Offert slutfinansiering"},
  {value: "Offert slutfinansiering färdigställd fastighet", label: "Offert slutfinansiering färdigställd fastighet"},
  {value: "Oreviderad balansrapport för bolagsborgen", label: "Oreviderad balansrapport för bolagsborgen"},
  {value: "Oreviderad balansrapport kredittagare", label: "Oreviderad balansrapport kredittagare"},
  {value: "Oreviderad balansrapport moderbolag", label: "Oreviderad balansrapport moderbolag"},
  {value: "Oreviderad resultatrapport för bolagsborgen", label: "Oreviderad resultatrapport för bolagsborgen"},
  {value: "Oreviderad resultatrapport kredittagare", label: "Oreviderad resultatrapport kredittagare"},
  {value: "Oreviderad resultatrapport moderbolag", label: "Oreviderad resultatrapport moderbolag"},
  {value: "Projektekonomi fullt uthyrd hyresfastighet", label: "Projektekonomi fullt uthyrd hyresfastighet"},
  {value: "Projektkalkyl", label: "Projektkalkyl"},
  {value: "Projektpresentation", label: "Projektpresentation"},
  {value: "Registreringsbevis", label: "Registreringsbevis"},
  {value: "Signerat entreprenadavtal", label: "Signerat entreprenadavtal"},
  {value: "Startbesked", label: "Startbesked"},
  {value: "Uppdaterat koncernschema upp till fysiska personer", label: "Uppdaterat koncernschema upp till fysiska personer"},
  {value: "Värdering fastighet hyresfastighet", label: "Värdering fastighet hyresfastighet"},
  {value: "Årsredovisning för bolagsborgen", label: "Årsredovisning för bolagsborgen"},
  {value: "Årsredovisning kredittagare", label: "Årsredovisning kredittagare"},
  {value: "Årsredovisning moderbolag", label: "Årsredovisning moderbolag"},
  {value: "Övrigt", label: "Övrigt"}
];

const optionsBostadsratter = [
  {value: "Aktieöverlåtelseavtal fastighetsförvärvet", label: "Aktieöverlåtelseavtal fastighetsförvärvet"},
  {value: "Avtal och reverser för transporten", label: "Avtal och reverser för transporten"},
  {value: "Bokningsavtal", label: "Bokningsavtal"},
  {value: "Boverket garanti", label: "Boverket garanti"},
  {value: "Bygglov", label: "Bygglov"},
  {value: "CV på utvecklaren", label: "CV på utvecklaren"},
  {value: "Ekonomiska planen (intygsgiven)", label: "Ekonomiska planen (intygsgiven)"},
  {value: "Entreprenadsavtal", label: "Entreprenadsavtal"},
  {value: "Ev andra reverser", label: "Ev andra reverser"},
  {value: "Ev underleverantörsavtal", label: "Ev underleverantörsavtal"},
  {value: "Ev värdering av marken", label: "Ev värdering av marken"},
  {value: "Finansieringskalkyl", label: "Finansieringskalkyl"},
  {value: "Förhandsavtal", label: "Förhandsavtal"},
  {value: "Förhandsvärdering som hyresfastighet", label: "Förhandsvärdering som hyresfastighet"},
  {value: "Försäkringar för projektet (föreningen och byggaren)", label: "Försäkringar för projektet (föreningen och byggaren)"},
  {value: "Köpeavtal på fastigheterna i projektet", label: "Köpeavtal på fastigheterna i projektet"},
  {value: "Lyftplan/tidsplan", label: "Lyftplan/tidsplan"},
  {value: "Mäklarprospekt (projektbeskrivning)", label: "Mäklarprospekt (projektbeskrivning)"},
  {value: "Oreviderad balansrapport för bolagsborgen", label: "Oreviderad balansrapport för bolagsborgen"},
  {value: "Oreviderad balansrapport kredittagare", label: "Oreviderad balansrapport kredittagare"},
  {value: "Oreviderad balansrapport moderbolag", label: "Oreviderad balansrapport moderbolag"},
  {value: "Oreviderad resultatrapport för bolagsborgen", label: "Oreviderad resultatrapport för bolagsborgen"},
  {value: "Oreviderad resultatrapport kredittagare", label: "Oreviderad resultatrapport kredittagare"},
  {value: "Oreviderad resultatrapport moderbolag", label: "Oreviderad resultatrapport moderbolag"},
  {value: "Projektkalkyl", label: "Projektkalkyl"},
  {value: "Registreringsbevis", label: "Registreringsbevis"},
  {value: "Signerat entreprenadavtal", label: "Signerat entreprenadavtal"},
  {value: "Startbesked", label: "Startbesked"},
  {value: "Uppdaterat koncernschema upp till fysiska personer", label: "Uppdaterat koncernschema upp till fysiska personer"},
  {value: "Upplåtelseavtal", label: "Upplåtelseavtal"},
  {value: "Årsredovisning för bolagsborgen", label: "Årsredovisning för bolagsborgen"},
  {value: "Årsredovisning kredittagare", label: "Årsredovisning kredittagare"},
  {value: "Årsredovisning moderbolag", label: "Årsredovisning moderbolag"},
  {value: "Övrigt", label: "Övrigt"},
];

const optionsEstateLoan = [
  { value: 'Fastighetskalkyl', label: 'Fastighetskalkyl' }, 
  { value: 'Förteckning fastigheter/tillgångar i koncernen', label: 'Förteckning fastigheter/tillgångar i koncernen' },
  { value: 'Hyreslista/rentroll', label: 'Hyreslista/rentroll' },
  { value: 'Oreviderad balansrapport kredittagare', label: 'Oreviderad balansrapport kredittagare'},
  { value: 'Oreviderad balansrapport moderbolag', label: 'Oreviderad balansrapport moderbolag'},
  { value: 'Oreviderad balansrapport för bolagsborgen', label: 'Oreviderad balansrapport för bolagsborgen'},
  { value: 'Oreviderad resultatrapport kredittagare', label: 'Oreviderad resultatrapport kredittagare'},
  { value: 'Oreviderad resultatrapport moderbolag', label: 'Oreviderad resultatrapport moderbolag'},
  { value: 'Oreviderad resultatrapport för bolagsborgen', label: 'Oreviderad resultatrapport för bolagsborgen'},
  { value: 'Registreringsbevis', label: 'Registreringsbevis'},
  { value: 'Uppdaterat koncernschema upp till fysiska personer', label: 'Uppdaterat koncernschema upp till fysiska personer' },
  { value: 'Värdering fastighet', label: 'Värdering fastighet' },
  { value: 'Årsredovisning kredittagare', label: 'Årsredovisning kredittagare' },
  { value: 'Årsredovisning moderbolag', label: 'Årsredovisning moderbolag' },
  { value: 'Årsredovisning för bolagsborgen', label: 'Årsredovisning för bolagsborgen' },
  { value: 'Övrigt', label: 'Övrigt' }
];

const optionsRefinancing = [
  { value: 'Fastighetskalkyl', label: 'Fastighetskalkyl' }, 
  { value: 'Förteckning fastigheter/tillgångar i koncernen', label: 'Förteckning fastigheter/tillgångar i koncernen' },
  { value: 'Hyresavtal', label: 'Hyresavtal'},
  { value: 'Hyreslista/rentroll', label: 'Hyreslista/rentroll' },
  { value: 'Oreviderad balansrapport kredittagare', label: 'Oreviderad balansrapport kredittagare'},
  { value: 'Oreviderad balansrapport moderbolag', label: 'Oreviderad balansrapport moderbolag'},
  { value: 'Oreviderad balansrapport för bolagsborgen', label: 'Oreviderad balansrapport för bolagsborgen'},
  { value: 'Oreviderad resultatrapport kredittagare', label: 'Oreviderad resultatrapport kredittagare'},
  { value: 'Oreviderad resultatrapport moderbolag', label: 'Oreviderad resultatrapport moderbolag'},
  { value: 'Oreviderad resultatrapport för bolagsborgen', label: 'Oreviderad resultatrapport för bolagsborgen'},
  { value: 'Registreringsbevis', label: 'Registreringsbevis'},
  { value: 'Underlag nuvarande finansiering', label: 'Underlag nuvarande finansiering' },
  { value: 'Uppdaterat koncernschema upp till fysiska personer', label: 'Uppdaterat koncernschema upp till fysiska personer' },
  { value: 'Värdering fastighet', label: 'Värdering fastighet' },
  { value: 'Årsredovisning kredittagare', label: 'Årsredovisning kredittagare' },
  { value: 'Årsredovisning moderbolag', label: 'Årsredovisning moderbolag' },
  { value: 'Årsredovisning för bolagsborgen', label: 'Årsredovisning för bolagsborgen' },
  { value: 'Övrigt', label: 'Övrigt' }
]

const optionsShortTermLoan = [
  { value: 'Fastighetskalkyl', label: 'Fastighetskalkyl' }, 
  { value: 'Förteckning fastigheter/tillgångar i koncernen', label: 'Förteckning fastigheter/tillgångar i koncernen' },
  { value: 'Hyreslista/rentroll', label: 'Hyreslista/rentroll' },
  { value: 'Oreviderad balansrapport kredittagare', label: 'Oreviderad balansrapport kredittagare'},
  { value: 'Oreviderad balansrapport moderbolag', label: 'Oreviderad balansrapport moderbolag'},
  { value: 'Oreviderad balansrapport för bolagsborgen', label: 'Oreviderad balansrapport för bolagsborgen'},
  { value: 'Oreviderad resultatrapport kredittagare', label: 'Oreviderad resultatrapport kredittagare'},
  { value: 'Oreviderad resultatrapport moderbolag', label: 'Oreviderad resultatrapport moderbolag'},
  { value: 'Oreviderad resultatrapport för bolagsborgen', label: 'Oreviderad resultatrapport för bolagsborgen'},
  { value: 'Registreringsbevis', label: 'Registreringsbevis'},
  { value: 'Uppdaterat koncernschema upp till fysiska personer', label: 'Uppdaterat koncernschema upp till fysiska personer' },
  { value: 'Underlag som styrker återbetalning av brygga', label: 'Underlag som styrker återbetalning av brygga' },
  { value: 'Värdering fastighet', label: 'Värdering fastighet' },
  { value: 'Årsredovisning kredittagare', label: 'Årsredovisning kredittagare' },
  { value: 'Årsredovisning moderbolag', label: 'Årsredovisning moderbolag' },
  { value: 'Årsredovisning för bolagsborgen', label: 'Årsredovisning för bolagsborgen' },
  { value: 'Övrigt', label: 'Övrigt' }
]

const optionsPropertyAquision = [
  { value: 'Aktielöverlåtelseavtalet', label: 'Aktielöverlåtelseavtalet'},
  { value: 'Fastighetskalkyl', label: 'Fastighetskalkyl' }, 
  { value: 'Förteckning fastigheter/tillgångar i koncernen', label: 'Förteckning fastigheter/tillgångar i koncernen' },
  { value: 'Hyreslista/rentroll', label: 'Hyreslista/rentroll' },
  { value: 'Hyresavtal', label: 'Hyresavtal'},
  { value: 'Köpeavtal förvärv', label: 'Köpeavtal förvärv' },
  { value: 'Oreviderad balansrapport kredittagare', label: 'Oreviderad balansrapport kredittagare'},
  { value: 'Oreviderad balansrapport moderbolag', label: 'Oreviderad balansrapport moderbolag'},
  { value: 'Oreviderad balansrapport för bolagsborgen', label: 'Oreviderad balansrapport för bolagsborgen'},
  { value: 'Oreviderad resultatrapport kredittagare', label: 'Oreviderad resultatrapport kredittagare'},
  { value: 'Oreviderad resultatrapport moderbolag', label: 'Oreviderad resultatrapport moderbolag'},
  { value: 'Oreviderad resultatrapport för bolagsborgen', label: 'Oreviderad resultatrapport för bolagsborgen'},
  { value: 'Proforma balansräkning', label: 'Proforma balansräkning'},
  { value: 'Registreringsbevis', label: 'Registreringsbevis'},
  { value: 'Uppdaterat koncernschema upp till fysiska personer', label: 'Uppdaterat koncernschema upp till fysiska personer' },
  { value: 'Värdering fastighet', label: 'Värdering fastighet' },
  { value: 'Årsredovisning kredittagare', label: 'Årsredovisning kredittagare' },
  { value: 'Årsredovisning moderbolag', label: 'Årsredovisning moderbolag' },
  { value: 'Årsredovisning för bolagsborgen', label: 'Årsredovisning för bolagsborgen' },
  { value: 'Övrigt', label: 'Övrigt' }
]

const optionsOmbildning = [
  { value: 'Avtal förvaltning', label: 'Avtal förvaltning' },
  { value: 'Bokningsavtal', label: 'Bokningsavtal'},
  { value: 'Ekonomisk översikt (kalkyl)', label: 'Ekonomisk översikt (kalkyl)' },
  { value: 'Ekonomisk plan', label: 'Ekonomisk plan' },
  { value: 'Hyreskontrakt lokal', label: 'Hyreskontrakt lokal' },
  { value: 'Proformabalansräkning', label: 'Proformabalansräkning' },
  { value: 'Registreringsbevis', label: 'Registreringsbevis' },
  { value: 'Stadgar', label: 'Stadgar' },
  { value: 'Teknisk statusbesiktning', label: 'Teknisk statusbesiktning' },
  { value: 'Upplåtelseavtal', label: 'Upplåtelseavtal'},
  { value: 'Värdering fastighet', label: 'Värdering fastighet' },
  { value: 'Övrigt', label: 'Övrigt' }
];

const optionsNyproduktion = [
  { value: 'Balansrapport förening', label: 'Balansrapport förening' },
  { value: 'Bokningsavtal', label: 'Bokningsavtal'},
  { value: 'Förhandsavtal', label: 'Förhandsavtal' },
  { value: 'Ekonomisk plan', label: 'Ekonomisk plan' },
  { value: 'Projektpresentation', label: 'Projektpresentation' },
  { value: 'Registreringsbevis', label: 'Registreringsbevis'},
  { value: 'Slutbesked', label: 'Slutbesked' },
  { value: 'Upplåtelseavtal', label: 'Upplåtelseavtal'},
  { value: 'Värdering fastighet', label: 'Värdering fastighet' },
  { value: 'Övrigt', label: 'Övrigt' }
];

  const UploaderSelect = (props) => {
    const { applicationType, projectType, disabled } = props;

    const handleOptions = (type, projectType) => {
      switch (type) {
        case 'TYPE_BUILDING_CREDIT':
          if (projectType === 'hyresrätter') {
            return optionsHyresratter // 'Byggnadskreditiv'
          } else {
            return optionsBostadsratter
          }
        
        case 'TYPE_BUILDING_CREDIT_HYRESRATTER':
          return optionsHyresratter
  
        case 'TYPE_BUILDING_CREDIT_BOSTADSRATTER':
          return optionsBostadsratter
  
        case 'TYPE_REAL_ESTATE_LOAN':
          return optionsEstateLoan // 'Fastighetslån'
  
        case 'TYPE_REFINANCING':
          return optionsRefinancing // 'Refinansiering'
  
        case 'TYPE_SHORT_TERM_LOAN':
          return optionsShortTermLoan //'Brygglån'
  
        case 'TYPE_PROPERTY_ACQUISITION':
          return optionsPropertyAquision // 'Fastighetsförvärv'
  
        case 'TYPE_ASSOCIATION_LOAN':
          if (projectType === 'Ombildning') {
            return optionsOmbildning // 'Föreningslån'
          } else {
            return optionsNyproduktion
          }
  
        default:
          return optionsAll;
      }
    }

  return (
    <>
      <CustomSelect
        name="fileType"
        key="fileType"
        inputprops={{
          size: 'large',
          placeholder: 'Välj en filtyp',
          disabled: disabled,
          onChange: (value) => {
            // setFileType(value)
            props.onChange(value)
          }
        }}
        options={handleOptions(applicationType, projectType)}
      />      
    </>
  );
};

export default UploaderSelect;
// export const getPageQuery = () => parse(window.location.href.split('?')[1]);
import { toast } from "react-toastify";
import TableFilter from "../uikit/TableFilter";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import '../scss/_toastify.scss'
import { useState } from "react";

export function getQueryParam(name) {
	const queryParams = new URLSearchParams(window.location.search);
	return queryParams.get(name) || "";
}

export const priorDate = new Date(
	new Date().setDate(new Date().getDate() - 30)
).toLocaleDateString("sv-SE");

export function getPriorDate() {
	return new Date(
		new Date().setDate(new Date().getDate() - 30)
	).toLocaleDateString("sv-SE");
}

export const getColumnSearchProps = ({
	isChecked = () => [],
	setChecked = (value) => {},
	removeChecked = () => {},
}) => ({
	filterDropdown: ({
		setSelectedKeys,
		selectedKeys,
		confirm,
		clearFilters,
		filters,
	}) => (
		<TableFilter
			setSelectedKeys={setSelectedKeys}
			selectedKeys={selectedKeys}
			confirm={confirm}
			clearFilters={clearFilters}
			filters={filters}
			setChecked={setChecked}
			removeChecked={removeChecked}
		/>
	),
	filteredValue: isChecked(),
});

export const getStatus = (status, loanOfferStatus = '') => {
	let className = "";
	let value = "-";
	if (loanOfferStatus === "STATUS_DECLINED_BY_BANK") {
		className = "red";
		value = "Avslagen";
	} else if (status === "STATUS_DRAFT") {
		className = "black";
		value = "Utkast";
	} else if (status === "STATUS_ACTIVE") {
		className = "black";
		value = "Aktiv";
	} else if (status === "STATUS_CANCELED") {
		className = "red";
		value = "Avslag";
	} else if (status === "STATUS_CLOSED") {
		className = "red";
		value = "Avslutad";
	} else if (status === "STATUS_REJECTED") {
		className = "red";
		value = "Avböjt";
	} else if (status === "STATUS_LOST") {
		className = "red";
		value = "Förlorad";
	} else if (status === "STATUS_PROCESSING") {
		className = "black";
		value = "Under handläggning";
	} else if (status === "STATUS_IN_REVIEW") {
		className = "black";
		value = "In review";
	} else if (status === "STATUS_OFFER_PUT") {
		className = "black";
		value = "Bud lagt";
	} else if (status === "STATUS_DECLINED") {
		className = "red";
		value = "Avböjt";
	} else if (status === "STATUS_APPROVED") {
		className = "green";
		value = "Godkänt";
	} else if (status === "CREATED") {
		className = "black";
		value = "Aktiv";
	} else if (status === "STATUS_PAID_OUT") {
		className = "green";
		value = "Utbetald";
	} else if (status === "STATUS_PAID_DOWN") {
		className = "black";
		value = "Återbetald";
	}

	return <span className={`${className}`}>{value}</span>;
};

export const getProduct = (type) => {
	switch (type) {
		case "TYPE_BUILDING_CREDIT":
			return "Byggnadskreditiv";

		case "TYPE_BUILDING_CREDIT_HYRESRATTER":
			return "Byggnadskreditiv hyresrätter";

		case "TYPE_BUILDING_CREDIT_BOSTADSRATTER":
			return "Byggnadskreditiv bostadsrätter";

		case "TYPE_REAL_ESTATE_LOAN":
			return "Fastighetslån";

		case "TYPE_REFINANCING":
			return "Refinansiering";

		case "TYPE_SHORT_TERM_LOAN":
			return "Brygglån";

		case "TYPE_PROPERTY_ACQUISITION":
			return "Fastighetsförvärv";

		case "TYPE_ASSOCIATION_LOAN":
			return "Föreningslån";

		default:
			return "-";
	}
};

export const getFilter = (name) => {
	let store = localStorage.getItem(name);
	return store === null || store === undefined || !store
		? []
		: JSON.parse(store);
};

export const setFilter = (name, value) => {
    localStorage.setItem(
        name,
        JSON.stringify(value)
    )
}

export const isFiltered = (filters, filterName) => {
	return !!filters.find(i => i === filterName)
}

export const successAlert = (message) => {
	toast.success(
		<div className="toast-body">
			<CheckCircleOutlined className="toast__icon toast__icon--success" />
			<p className="toast-text">{message}</p>
		</div>
	)
}

export const errorAlert = (message) => {
	toast.error(
		<div className="toast-body">
			<CloseCircleOutlined className="toast__icon toast__icon--error" />
			<p className="toast-text">{message}</p>
		</div>)
}

export const useSsn = (initialSsn = "") => {
	const [ssnInner, setInnerSsn] = useState(initialSsn)

	function setFormatedSsn(rawSsn) {
		let newval = rawSsn !== undefined ? rawSsn.replace(/[^-\d]/gm, "") : "";
		newval =
			!!newval.match(/-/g) && newval.match(/-/g).length > 1
				? ssnInner
				: newval;
		setInnerSsn(newval);
	}

	return [ssnInner, setFormatedSsn];
}

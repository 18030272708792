import React, { useState } from 'react';
import {
  Button,
  Card,
  Table,
  Row,
  Col,
  Spin
} from 'antd';
import { ReactComponent as SearchIcon } from '../../../../assets/admin/search.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/admin/download.svg';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import http from "../../../../api";
import { PreviewModal } from "../Modal/PreviewModal";
import { DeleteModal } from "../Modal/DeleteModal";
import { fetchApplication } from '../../../../actions/applications';
import "../style.scss";

const topColResponsiveProps3 = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  style: {
    marginBottom: 24,
  },
};

const { Column } = Table;

const FilesList = (props) => {
  const { loanDataById } = props;

  const [ selectedFile, setSelectedFile ] = useState({});
  const [ showPreviewModal, setShowPreviewModal ] = useState(false);
  const [ showPreviewDeleteModal, setShowPreviewDeleteModal ] = useState(false);
  const [ isDeleting, setIsDeleting ] = useState(false);
  
  const handleCancelPreview = async () => {
    setShowPreviewModal(value => !value);
    setSelectedFile({})
  };

  const handleCancelDeletePreview = async () => {
    setShowPreviewDeleteModal(value => !value);
    setSelectedFile({})
  }; 

  const handleDownloadFile = (file) => {

		http(`/api/v1/application/${file.id}/file`, {
			method: 'get',
			responseType: 'blob'
		})
		.then((blob) => {
		  const url = window.URL.createObjectURL(
			new Blob([blob.data]),
		  );

		  const link = document.createElement('a');
		  link.href = url;
		  link.setAttribute(
			'download',
			`${file.name}`,
		  );
	  
		  // Append to html link element page
		  document.body.appendChild(link);
	  
		  // Start download
		  link.click();
	  
		  // Clean up and remove the link
		  link.parentNode.removeChild(link);
		})
		.catch(err => {
		});
	}

  const handleDeleteModal = (file) => {
    setSelectedFile(file);
    setShowPreviewDeleteModal(true);
	}

  const handleDeleteFile = (file) => {
    setIsDeleting(true);
		http(`/api/v1/application/${file.id}/file`, {
			method: 'DELETE'
		})
		.then((request) => {
      setIsDeleting(false);
      handleCancelDeletePreview(false);
      props.fetchApplication(props.id);
		})
		.catch(err => {
			setIsDeleting(false);
		});
	}

  const handleDownloadArchive = () => {

		http(`/api/v1/application/${loanDataById?.id}/zip`, {
			method: 'get',
			responseType: 'blob'
		})
		.then((blob) => {
		  // Create blob link to download
		  const url = window.URL.createObjectURL(
			new Blob([blob.data]),
		  );

		  const link = document.createElement('a');
		  link.href = url;
		  link.setAttribute(
			'download',
			`${loanDataById?.id} Applikationsfiler.zip`,
		  );
	  
		  // Append to html link element page
		  document.body.appendChild(link);
	  
		  // Start download
		  link.click();
	  
		  // Clean up and remove the link
		  link.parentNode.removeChild(link);
		})
		.catch(err => {
			// console.log('err', err);
		});
	}

  const handlePrewiewModal = async (file) => {
    http(`/api/v1/application/${file.id}/file`, {
			method: 'get',
			responseType: 'blob'
		})
    .then(blob => {
      const url = window.URL.createObjectURL(
        new Blob([blob.data], { type: blob.data.type })
      );
      
      let extensions = ['doc', 'docx', 'rtf', 'xls', 'xlsx'];
      let isMsDocFileType = extensions.some(extension => extension === file.extension);
      if (isMsDocFileType) {
        setSelectedFile(file)
        setShowPreviewModal(value => !value);
      } else {
        window.open(url);
      }
    })
  };

  return (
    <>
      <Row className="dashboard loanOffersTableWrapper" gutter={24} >
      <Col {...topColResponsiveProps3}>
        <Card
            // loading={loading}
            style={{marginBottom: 30}}
            bodyStyle={{
              padding: '0',
              borderRadius: 6
            }}
          >
            <div className="tableTitle">
              <div>{'Alla bifogade filer '}</div>
              <div style={{display: "flex", alignItems: "center"}}>
                <div>Ladda ner alla</div>
                <Button
                  className="iconBtn" 
                  onClick={() => handleDownloadArchive()}
                  style={{padding: '2px 5px 5px 5px'}}
                >
                  <DownloadIcon />
                </Button>
              </div>
            </div>
            <Spin spinning={props.isLoading}>
              <Table
                dataSource={loanDataById?.files ? loanDataById.files : [] }
                pagination={false}
                rowKey="id"
                key="id"
                scroll={{ x: 'fit-content' }}
              >
                <Column
                  title="Fil"
                  dataIndex="name"
                  key="name"
                  render={(text, record) => (
                    <span className="tableFile">{record.name}</span>
                  )}
                />
                <Column
                  title="Type"
                  dataIndex="name"
                  key="type"
                  render={(text, record) => (
                    <span>
                      {record.fileType}
                    </span>
                  )}
                />
                <Column
                  title="Tillagt"
                  dataIndex="date"
                  key="date"
                  render={(text, record) => (
                    <span className="tableFile">{moment(record.createdAt).format('YYYY-MM-DD')}</span>
                  )}
                />
                <Column
                  title="Åtgärder"
                  dataIndex="action"
                  key="action"
                  render={(text, record) => (
                    <div className="iconBtnsWrapper">
                      <Button
                        className="iconBtnTable"
                        onClick={() => handlePrewiewModal(record)}
                      >
                        <SearchIcon />
                      </Button>
                      <Button
                        className="iconBtnTable" 
                        onClick={() => handleDownloadFile(record)}
                      >
                        <DownloadIcon />
                      </Button>
                      <Button
                        className="iconBtnTable"
                        onClick={() => handleDeleteModal(record)}
                      >
                        <DeleteOutlined className="iconDelete" />
                      </Button>
                    </div>
                  )}
                />
            </Table>
          </Spin>
        </Card>
      </Col>
    </Row>
    <PreviewModal
      showLeadDialog={showPreviewModal}
      handleCancel={handleCancelPreview}
      onSubmit={handleCancelPreview}
      fileItem={selectedFile}
    />
    <DeleteModal
      showLeadDialog={showPreviewDeleteModal}
      handleCancel={handleCancelDeletePreview}
      onSubmit={handleCancelDeletePreview}
      fileItem={selectedFile}
      onDelete={handleDeleteFile}
      isLoading={isDeleting}
    />
  </>
  )
};

const mapStateToProps = (store) => {
  return {
    loanDataById: store.applications.application,
    isLoading: store.applications.isFetching_application
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApplication: (id) => dispatch(fetchApplication(id)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FilesList);
